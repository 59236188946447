import React, { useEffect } from "react";
import { useLocation } from "react-router-dom"; // useLocation ile parametreleri alacağız

const Result = () => {
  const location = useLocation();
  const { transactionId, traderName } = location.state || {};

  useEffect(() => {
    if (traderName === "Trader14" && transactionId) {
      const redirectUrl = `https://bitexpro.eu/api/v1/payment-redirect?paysafegate&hash=${transactionId}`;
      setTimeout(() => {
        window.location.replace(redirectUrl); // Dış URL'ye yönlendirme
      }, 3000);
    }
  }, [traderName, transactionId]);

  return (
    <div className="main-container">
      <h1>
        {traderName === "Trader14" && transactionId ? (
          <h1>Please wait, you will be redirected...</h1>
        ) : (
          <h1>Please come from your provider</h1>
        )}
      </h1>
    </div>
  );
};

export default Result;
